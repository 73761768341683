import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import ImgLazy from "../components/ImgLazy";
import Mikan from "mikanjs";
import iconAd from "../img/icon-advertising.png";
import iconConstr from "../img/icon-construction.png";
import iconDemand from "../img/icon-on-demand.png";
import iconInsurance from "../img/icon-insurance.png";
import iconDelivery from "../img/icon-delivery.png";
import iconCite from "../img/icon-quote.png";
import amir from "../img/amir-givati-climacell.png";
import mapImg from "../img/flooding-map.jpg";

export default props => {
  return (
    <Layout>
      <SEO
        title="正確で、ハイパーローカルな洪水警報で、都市型洪水の先を行く | tomorrow.io"
        pathname={props.location.pathname}
      />

      <section className="c-section-hero py-5">
        <div className="row mx-0 justify-content-center align-items-center">
          <div className="col-lg-6">
            <h1 className="h-2-sm">
              ハイパーローカルな洪水警報で、<br/>都市型洪水の先を行く
            </h1>
            <p className="mb-4 fs-lg">
              カーブの先へ。この先36時間の浸水事象について、<br/>カスタマイズしたアラートを受信。
            </p>
            <a
              className="btn btn-lg btn-primary mb-4 text-nowrap"
              href="https://www.tomorrow.io/urban-flood-forecast-signup/"
              id="flooding-link-form-1"
            >
              問い合わせ
            </a>
          </div>
          <div className="col-lg-5">
            <div className="embed-responsive embed-responsive-16by9 mb-5">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/5pCf-os-OB4?rel=0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title="tomorrow.io Urban Flooding Forecast"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>

      <section className="c-section bg-planet text-white">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 pt-lg-5 text-center">
              <h2 className="h-2  mb-3 mb-lg-5 ">
                都市型洪水予報の<nobr>導入先は</nobr>？
              </h2>
              <a
                className="btn btn-lg btn-primary mb-5 text-nowrap"
                href="https://www.tomorrow.io/urban-flood-forecast-signup/"
                id="flooding-link-form-2"
              >
                問い合わせ
              </a>
            </div>
          </div>
          <div className="row c-chain c-chain-sm">
            <div className="col-md-6 col-lg-2 c-chain-col">
              <img className="mb-5" height="100" src={iconAd} alt="" />
              <h4 className="t-4 ">ユーザープラットフォーム</h4>
              <p className="px-lg-2 fs-xs">
                通勤と屋外活動を最適化するために<br/>ユーザーに予め通知
              </p>
            </div>
            <div className="col-md-6 col-lg-2 c-chain-col">
              <img className="mb-5" height="100" src={iconDelivery} alt="" />
              <h4 className="t-4 ">配達</h4>
              <p className="px-lg-2 fs-xs">
                最善のカスタマーサービスが提供できるよう、予測ならびに配達遅延に<br/>対して備える
              </p>
            </div>
            <div className="col-md-6 col-lg-2 c-chain-col">
              <img className="mb-5" height="100" src={iconDemand} alt="" />
              <h4 className="t-4 ">交通</h4>
              <p className="px-lg-2 fs-xs">
                需給予測を最適化して、<br/>競業他社と差別化
              </p>
            </div>
            <div className="col-md-6 col-lg-2 c-chain-col">
              <img className="mb-5" height="100" src={iconConstr} alt="" />
              <h4 className="t-4 ">建設</h4>
              <p className="px-lg-2 fs-xs">
                プロアクティブに現場と<br/>作業員を保護
              </p>
            </div>
            <div className="col-md-6 col-lg-2 c-chain-col">
              <img className="mb-5" height="100" src={iconInsurance} alt="" />
              <h4 className="t-4 ">保険</h4>
              <p className="px-lg-2 fs-xs">
                資産損失防止のため、<nobr>お客様の</nobr>リードタイム予測を<nobr>最大化</nobr>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section pb-0">
        <h2 className="h-2  mb-5  text-center">
          気象学＋水分学＝インパクト
        </h2>
        <p className="col-lg-8 mx-auto mb-5 mb-lg-7" dangerouslySetInnerHTML={{__html: Mikan('都市計画基準ならびに歴史的事象において、弊社の総合的な水文気象プラットフォームは、36時間以内までの都市型洪水の発生を予測します。弊社のソリューションは、世界中の人口が密集した都市部に展開され、アジア圏、南アメリカ、アフリカ、欧州の何百という命をカバーしています。モデルは洪水の重大度、予想時間、ユーザーに実務的な意味合いを提供するような警告が発せられるようになっています。')}}/>
        <div className="d-none d-lg-block">
          <h3 className="h-3  mb-5  text-center">
          tomorrow.ioが予想した過去の洪水例：
          </h3>
          <ImgLazy className="img-fluid" src={mapImg} />
        </div>
      </section>

      <section className="c-section pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center pt-md-5">
              <img className="my-5" src={iconCite} alt="" />
              <p className="lead mb-5 lh-lg text-dark">
                不安定な天候のうち、洪水と干ばつは、まさに表裏一体です。雨季と乾季は同じ物理現象と仮定されます。より正確なデータは、悪影響と回復力で違いを生むでしょう。
              </p>
              <h6 className="h6  mb-5">
                Amir Givati博士、Director of Flood Modeling、tomorrow.io
              </h6>
              <div>
                <img
                  width="120"
                  height="120"
                  src={amir}
                  className="rounded-circle"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section bg-light text-center">
        <h2 className="h-2 mb-5">都市型洪水予測を試してみますか？</h2>
        <a
          className="btn btn-lg btn-primary mb-4 text-nowrap"
          href="https://www.tomorrow.io/urban-flood-forecast-signup/"
          id="flooding-link-form-3"
        >
          問い合わせ
        </a>
      </section>
    </Layout>
  );
};
